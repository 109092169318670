import { useReducer, useMemo } from "react";
import { z, ZodObject, ZodTypeAny, ZodRawShape } from "zod";

export type FormType<T> = T & { submitted: boolean };
export type FormActionType<T> = ActionType<T> | { type: "submit" };
type ActionType<T> =
  | {
      type: "update";
      payload: {
        [K in keyof T]: {
          key: K;
          value: T[K];
        };
      }[keyof T];
    }
  | {
      type: "update-array";
      payload: {
        [K in keyof T]: T[K] extends Array<infer Z>
          ?
              | { key: K; type: "add"; value: Z }
              | {
                  key: K;
                  type: "update";
                  index: number;
                  value: ActionType<Z>;
                }
              | { key: K; type: "delete"; index: number }
          : never;
      }[keyof T];
    }
  | {
      type: "update-within";
      payload: {
        [K in keyof T]: {
          key: K;
          value: T[K] extends object ? ActionType<T[K]> : never;
        };
      }[keyof T];
    };

export type ErrorType<T> = Partial<{
  [K in keyof T]: T[K] extends Array<infer Z>
    ? Record<number, (ErrorType<Z> & { errors?: string[] }) | undefined> & {
        errors?: string[];
      }
    : T[K] extends object
      ? ErrorType<T[K]>
      : { errors: string[] };
}>;

function update<X>(state: X, action: ActionType<X>): X {
  switch (action.type) {
    case "update": {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case "update-array": {
      switch (action.payload.type) {
        case "add": {
          const currentArray = state[action.payload.key];
          if (Array.isArray(currentArray)) {
            return {
              ...state,
              [action.payload.key]: [...currentArray, action.payload.value],
            };
          }
          return { ...state };
        }
        case "delete": {
          const stateArray = state[action.payload.key];
          if (Array.isArray(stateArray)) {
            const newArray = Array.from(stateArray);
            newArray.splice(action.payload.index, 1);
            return {
              ...state,
              [action.payload.key]: newArray,
            };
          }
          return { ...state };
        }
        case "update": {
          const currentArray = state[action.payload.key];
          if (Array.isArray(currentArray)) {
            if (action.payload.value.type === "update") {
              const downLayerState = currentArray[action.payload.index];
              const downLayerAction = action.payload.value;
              const value = {
                ...downLayerState,
                ...update(downLayerState, downLayerAction),
              };
              currentArray[action.payload.index] = value;

              return {
                ...state,
                [action.payload.key]: currentArray,
              };
            } else {
              // todo test this
              const downLayerState = currentArray[action.payload.index];
              const downLayerAction = action.payload.value;
              const value = {
                ...downLayerState,
                ...update(downLayerState, downLayerAction),
              };
              currentArray[action.payload.index] = value;

              return {
                ...state,
                [action.payload.key]: currentArray,
              };
            }
          }
          return { ...state };
        }
      }
      break; // todo doesnt need this only needed for code sandbox
    }
    case "update-within": {
      const downLayerState = state[action.payload.key];
      const downLayerAction = action.payload.value;
      return {
        ...state,
        [action.payload.key]: update(downLayerState, downLayerAction),
      };
    }
  }
}

function reducer<T>(state: FormType<T>, action: FormActionType<T>) {
  switch (action.type) {
    case "update":
    case "update-array":
    case "update-within": {
      const updatedState = update(state, action);
      return { ...state, ...updatedState };
    }
    case "submit": {
      return {
        ...state,
        submitted: true,
      };
    }
  }
}

export function useFormReducer<T extends Record<keyof T, T[keyof T]>>(
  defaultValue: T,
  schema: ZodObject<ZodRawShape, "strip", ZodTypeAny, T>,
): [FormType<T>, (action: FormActionType<T>) => void, ErrorType<T>] {
  const [state, dispatch] = useReducer(
    (state: FormType<T>, action: FormActionType<T>) => reducer(state, action),
    { ...defaultValue, submitted: false },
  );

  const errors = useMemo<ErrorType<T>>(() => {
    try {
      schema.partial().parse(state);
    } catch (err) {
      if (err instanceof z.ZodError) {
        const error: ErrorType<T> = {};
        err.issues.map((issue) => {
          let pathError: Record<any, any> = error;
          issue.path.forEach((p, i) => {
            pathError[p] = { ...(pathError[p] ?? []) };
            if (issue.path.length - 1 === i) {
              pathError[p] = { errors: issue.message };
            }
            pathError = pathError[p];
          });
        });

        return error;
      }
    }
    return {};
  }, [state]);

  const newDispatch = (action: FormActionType<T>) => {
    dispatch(action);
  };
  return [state, newDispatch, errors];
}
