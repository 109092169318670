import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {MapPage} from "./pages/map/MapPage";
import {SearchPage} from "./pages/search/SearchPage";
import {SearchResult} from "./pages/search/result/SearchResult";
import {AccountPage} from "./pages/account/AccountPage";
import {HomePage} from "./pages/home/HomePage";
import AccessController from "./services/AccessController";
import "@aws-amplify/ui-react/styles.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>
  },
  {
    path: "/*",
    element: <AccessController/>,
    children: [
      {
        path: 'map',
        element: <MapPage/>
      },
      {
        path: 'search/*',
        element: <SearchPage/>,
        children: [
          {
            path: ':parkId',
            element: <SearchResult/>
          }
        ]
      },
      {
        path: 'account',
        element: <AccountPage/>
      },
    ]
  },
])

export const App = () => <RouterProvider router={router}/>


