import Typography from "@mui/material/Typography";
import {AppNavigator} from "../../components/AppNavigator";
import React from "react";
import "./HomePage.css"
import Button from "@mui/material/Button";

export function HomePage() {
  return <div className={'HomePage'}>
    <Typography className={'HomePage-header'} component="h1" variant="h5" fontWeight={"bold"}>
      🐾 Welcome To Dog Zones 🐾
    </Typography>
    <div className={"HomePageContent"}>
      <Typography component="div" variant="body1" paddingBottom={2}>
        Unleash the joy of outdoor adventures with your fury friend using <b>Dog Zones</b>, the ultimate guide to dog
        friendly parks in and around the Greater Wellington area!<br/>
        <b>Dog Zones</b> is tailored for dog lovers seeking the perfect spot for their pups to play, socialize, and
        enjoy the freedom and nature.<br/>
        With <b>Dog Zones</b>, every day is a paw-some day at the park! 🌲🐕
      </Typography>
      <Typography component="div" variant="subtitle1" fontWeight={"bold"}>
        Features:
      </Typography>
        <ul>
          <li><Typography component="div" variant="body1">
            <b>Explore:</b> Discover new dog parks with detailed descriptions, using the map or search.
          </Typography></li>
          <li><Typography component="div" variant="body1">
            <b>Share:</b> Easily share any park you find with the in built share function
          </Typography></li>
          <li><Typography component="div" variant="body1">
            <b>Navigate:</b> Get there with our integration with google or apple maps
          </Typography></li>
        </ul>
      <Typography component="div" variant="subtitle1" fontWeight={"bold"}>
        Coming Soon:
      </Typography>
      <ul>
        <li><Typography component="div" variant="body1">
          <b>Enhance Explore:</b> Get detailed amenities for each park and user ratings.
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Socialize:</b> Connect with the community of dog lovers, share tips, arrange play dates, and make new
          friends.
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Stay Informed:</b> Get community updates on park conditions, or community events
        </Typography></li>
        <li><Typography component="div" variant="body1">
          <b>Personalise:</b> Save your Favorite parks, set reminders, and track your visits
        </Typography></li>
      </ul>
      <Typography component="div" variant="subtitle1" fontWeight={"bold"} paddingBottom={1}>
        📣 We Value Your Bark! 📣
      </Typography>
      <Typography component="div" variant="body1">
        At <b>Dog Zones</b>, your feedback is the treat that helps us improve! As a solo developer dedicated to creating the best experience for you and your canine companions,
        I'm all ears for your suggestions, ideas, and paw-sitive feedback.<br/>
        <b>Got a new park to add?</b> Let's make <b>Dog Zones</b> better together! If you know of a park that's not on our list, share the details,
        and lets collaborate to expand our dog park universe. Your input is invaluable in helping <b>Dog Zones</b> grow and thrive.<br/>
      </Typography>
      <Typography component="div" variant="body1" paddingTop={1}>
        Together, we can make <b>Dog Zones</b> the go-to place for every dog owner's needs. Because when it comes to dog parks,
        its not just about the space, its about the community. 🐾💬
      </Typography>
      <Button
        href={"mailto: contact@dog-zones.com"}
        type={"button"}
        fullWidth
        variant="contained"
        sx={{mt: 3, mb: 2}}
      >
        Contact Us
      </Button>
    </div>
    <AppNavigator/>
  </div>
}