import { Style } from "mapbox-gl";

const ARIAL_SHOW_ZOOM = 14

export const getStyleJson = (baseMapsApiKey: string): Style => ({
  "version": 8,
  "id": "st_topolite",
  "name": "topolite",
  "sources": {
    "basemaps-aerial": {
      "type": "raster",
      "tiles": [
        `https://basemaps.linz.govt.nz/v1/tiles/aerial/WebMercatorQuad/{z}/{x}/{y}.webp?api=${baseMapsApiKey}`
      ],
      "tileSize": 256
    },
    "LINZ Basemaps": {
      "type": "vector",
      "attribution": "© 2022 Toitū Te Whenua - CC BY 4.0",
      "url": `https://basemaps.linz.govt.nz/v1/tiles/topographic/EPSG:3857/tile.json?api=${baseMapsApiKey}`
    },
    "LINZ-Texture-Relief": {
      "tiles": [
        `https://basemaps.linz.govt.nz/v1/tiles/texturereliefshade/EPSG:3857/{z}/{x}/{y}.webp?api=${baseMapsApiKey}`
      ],
      "type": "raster",
      "minzoom": 0,
      "maxzoom": 20,
      "tileSize": 256
    },
    "basemaps-elevation-terrain-rgb-dem": {
      "type": "raster-dem",
      "tiles": [
        `https://basemaps.linz.govt.nz/v1/tiles/elevation/WebMercatorQuad/{z}/{x}/{y}.png?api=${baseMapsApiKey}&pipeline=terrain-rgb`
      ],
      "tileSize": 256
    }
  },
  "layers": [
    {
      "id": "basemaps-aerial",
      "type": "raster",
      "source": "basemaps-aerial",
      "paint": {
        "raster-opacity": 1
      },
      "minzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "dock"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "background-color": "rgba(228,248,255,0.5)"
      },
      "id": "Background",
      "type": "background",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "sand"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(220, 205, 177, 0.3)"
      },
      "id": "Landcover-Sand",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "minzoom": 8,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "wetland_feature",
          "mangrove"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(184, 199, 155, 0.3)"
      },
      "id": "Landuse-Mangrove",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "fill",
      "minzoom": 12,
      "maxzoom": ARIAL_SHOW_ZOOM

    },
    {
      "filter": [
        "all"
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": {
          "stops": [
            [
              1,
              "rgba(234, 233, 229, 1)"
            ],
            [
              10,
              "#FCFBF7"
            ]
          ]
        },
        "fill-antialias": true,
        "fill-translate-anchor": "map"
      },
      "id": "Coastline2",
      "source": "LINZ Basemaps",
      "source-layer": "coastline",
      "type": "fill",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "grass"
        ],
        [
          "==",
          "natural",
          "scrub"
        ],
        [
          "==",
          "distribution",
          "scattered"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(194,194,194,0.4)",
        "fill-antialias": false,
        "fill-outline-color": "rgba(210, 210, 210, 0.1)"
      },
      "id": "Vegetation-Scatteredscrub",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "grass"
        ],
        [
          "==",
          "natural",
          "scrub"
        ],
        [
          "!has",
          "distribution"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(196,204,198,0.3)",
        "fill-outline-color": {
          "stops": [
            [
              6,
              "rgba(255, 255, 255, 0.1)"
            ],
            [
              10,
              "rgba(255, 255, 255, 0.1)"
            ],
            [
              19,
              "rgba(255, 255, 255, 0.1)"
            ]
          ]
        }
      },
      "id": "Vegetation-Scrub",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "tree",
          "exotic"
        ],
        [
          "==",
          "landuse",
          "wood"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": {
          "stops": [
            [
              6,
              "rgba(194,194,194,0.4)"
            ],
            [
              11,
              "rgba(194,194,194,0.4)"
            ]
          ]
        },
        "fill-outline-color": "rgba(210, 210, 210, 0)"
      },
      "id": "Vegetation-Exotic",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "tree",
          "native"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": {
          "stops": [
            [
              6,
              "rgba(194,194,194,0.4)"
            ],
            [
              9,
              "rgba(194,194,194,0.4)"
            ],
            [
              11,
              "rgba(194,194,194,0.4)"
            ],
            [
              14,
              "rgba(194,194,194,0.4)"
            ]
          ]
        },
        "fill-outline-color": "rgba(210, 210, 210, 0)"
      },
      "id": "Vegetation-Native",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "ice"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(255, 255, 255, 0.44)",
        "fill-outline-color": {
          "stops": [
            [
              8,
              "rgba(255, 255, 255, 0.01)"
            ],
            [
              10,
              "rgba(211, 249, 249, 0.5)"
            ],
            [
              11,
              "rgba(57, 158, 158, 0.5)"
            ]
          ]
        }
      },
      "id": "Landcover-Ice",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "landuse",
          "orchard"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(194,194,194,0.1)",
        "fill-translate-anchor": "viewport",
        "fill-outline-color": "rgba(255, 255, 255, 1)"
      },
      "id": "Landcover-Orchard",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "landuse",
          "vineyard"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(194,194,194,0.2)",
        "fill-translate-anchor": "viewport",
        "fill-antialias": false,
        "fill-outline-color": "rgba(255, 255, 255, 1)"
      },
      "id": "Landcover-Vineyard",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "wetland"
        ],
        [
          "==",
          "wetland_type",
          "swamp"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": {
          "stops": [
            [
              6,
              "rgba(205, 232, 230, 1)"
            ],
            [
              14,
              "rgba(224, 236, 238, 1)"
            ]
          ]
        }
      },
      "id": "Landcover-Swamp-Fill",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "any",
        [
          "==",
          "class",
          "mine"
        ],
        [
          "==",
          "class",
          "quarry"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(205, 205, 205, 1)",
        "fill-opacity": 0.5
      },
      "id": "Poi-Mine-Quarry-Poly",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "fill",
      "minzoom": 12,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "wetland"
        ],
        [
          "==",
          "wetland_type",
          "swamp"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": 0.5,
        "line-dasharray": [
          6,
          6,
          4,
          4
        ],
        "line-color": {
          "stops": [
            [
              10,
              "rgba(0, 140, 204, 0.1)"
            ],
            [
              11,
              "rgba(0, 140, 204, 0.8)"
            ]
          ]
        }
      },
      "id": "Landcover-Swamp-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "line",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "!has",
          "designated"
        ],
        [
          "!=",
          "type",
          "index"
        ],
        [
          "!has",
          "nat_form"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "maxzoom": 16,
      "paint": {
        "line-width": {
          "stops": [
            [
              12,
              0.3
            ],
            [
              16,
              0.2
            ]
          ]
        },
        "line-color": "rgba(232, 184, 77, 0.75)"
      },
      "id": "Contours-All",
      "source": "LINZ Basemaps",
      "source-layer": "contours",
      "type": "line",
      "minzoom": 9,
    },
    {
      "filter": [
        "all",
        [
          "has",
          "designated"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "maxzoom": 16,
      "paint": {
        "line-width": 0.4,
        "line-color": "rgba(232, 184, 77, 0.75)",
        "line-dasharray": [
          30,
          30
        ]
      },
      "id": "Contours-Designated",
      "source": "LINZ Basemaps",
      "source-layer": "contours",
      "type": "line",
      "minzoom": 9
    },
    {
      "filter": [
        "all",
        [
          "==",
          "type",
          "index"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "maxzoom": 16,
      "paint": {
        "line-width": {
          "stops": [
            [
              10,
              0.5
            ],
            [
              15,
              1.2
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              10,
              "rgba(232, 184, 77, 0.5)"
            ],
            [
              15,
              "rgba(232, 184, 77, 0.5)"
            ]
          ]
        }
      },
      "id": "Contours-Index",
      "source": "LINZ Basemaps",
      "source-layer": "contours",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "has",
          "nat_form"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "maxzoom": 16,
      "paint": {
        "line-width": 0.6,
        "line-color": "rgba(232, 184, 77, 0.5)",
        "line-dasharray": [
          30,
          10,
          10,
          10
        ]
      },
      "id": "Contours-Natural",
      "source": "LINZ Basemaps",
      "source-layer": "contours",
      "type": "line",
      "minzoom": 9
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "aerodrome"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(224, 224, 224, 0.7)"
      },
      "id": "Aeroway-Airport",
      "source": "LINZ Basemaps",
      "source-layer": "aeroway",
      "type": "fill",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "runway"
        ],
        [
          "==",
          "surface",
          "sealed"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(209, 209, 209, 0.5)",
        "fill-antialias": false
      },
      "id": "Aeroway-Airstrip-Sealed",
      "source": "LINZ Basemaps",
      "source-layer": "aeroway",
      "type": "fill",
      "minzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "runway"
        ],
        [
          "==",
          "surface",
          "sealed"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              2,
              1
            ],
            [
              10,
              0.7
            ],
            [
              19,
              0.5
            ]
          ]
        },
        "line-color": "rgba(125, 125, 125, 1)"
      },
      "id": "Aeroway-Runway-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "aeroway",
      "type": "line",
      "minzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "runway"
        ],
        [
          "!has",
          "surface"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-color": "rgba(125, 125, 125, 1)",
        "line-dasharray": [
          5,
          5
        ]
      },
      "id": "Aeroway-Airstrip-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "aeroway",
      "type": "line",
      "minzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "any",
        [
          "==",
          "class",
          "lake"
        ],
        [
          "==",
          "class",
          "river"
        ],
        [
          "==",
          "class",
          "canal"
        ],
        [
          "==",
          "class",
          "lagoon"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              9,
              1
            ],
            [
              15,
              2.5
            ]
          ]
        },
        "line-offset": 0,
        "line-color": {
          "stops": [
            [
              6,
              "rgba(184, 220, 242, 1)"
            ],
            [
              13,
              "rgba(0, 140, 204, 0.3)"
            ],
            [
              19,
              "rgba(0, 140, 204, 1)"
            ]
          ]
        }
      },
      "id": "Water-Polys-Outline",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "line",
      "minzoom": 11,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "canal"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-opacity": 1,
        "fill-translate-anchor": "map",
        "fill-antialias": true,
        "fill-outline-color": {
          "stops": [
            [
              6,
              "rgba(184, 220, 242, 1)"
            ],
            [
              13,
              "rgba(0, 140, 204, 0.3)"
            ],
            [
              19,
              "rgba(0, 140, 204, 1)"
            ]
          ]
        }
      },
      "id": "Water-Canal-Poly-Named",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "fill",
      "minzoom": 9,
      // "maxzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "lagoon"
        ]
      ],
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-antialias": true
      },
      "id": "Water-Lagoon",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "fill",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "lake"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-opacity": 1,
        "fill-translate-anchor": "map",
        "fill-antialias": true
      },
      "id": "Water-Lake",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "fill",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "lake"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-opacity": 1,
        "fill-translate-anchor": "map",
        "fill-antialias": true
      },
      "id": "Water-Lake-Named",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "fill",
      "minzoom": 8,
      // "maxzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "river"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-opacity": 1,
        "fill-translate-anchor": "map",
        "fill-antialias": true
      },
      "id": "Water-River-Poly-Named",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "fill",
      "minzoom": 8,
      // "maxzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "any",
        [
          "==",
          "class",
          "canal"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      // "maxzoom": 21,
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-opacity": 1,
        "fill-translate-anchor": "map",
        "fill-antialias": true
      },
      "id": "Water-Canal-Poly",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "fill",
      "minzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "any",
        [
          "==",
          "class",
          "river"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      // "maxzoom": 21,
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-opacity": 1,
        "fill-translate-anchor": "map",
        "fill-antialias": true
      },
      "id": "Water-River-Poly",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "fill",
      "minzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "reef"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              2,
              0.5
            ],
            [
              10,
              1
            ]
          ]
        },
        "line-color": "rgba(0, 140, 204, 1)",
        "line-dasharray": [
          12,
          2
        ]
      },
      "id": "Water-Reef",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "line",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "canal"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-opacity": 1,
        "line-width": {
          "stops": [
            [
              12,
              1
            ],
            [
              13,
              0.75
            ],
            [
              18,
              0.5
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              11,
              "rgba(167, 209, 232, 0.75)"
            ],
            [
              13,
              "rgba(76, 147, 226, 0.75)"
            ],
            [
              20,
              "rgba(0, 140, 204, 0.75)"
            ]
          ]
        }
      },
      "id": "Waterway-Canal-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "waterway",
      "type": "line",
      "minzoom": 13,
      // "maxzoom": 21,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "drain"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-opacity": 1,
        "line-width": {
          "stops": [
            [
              12,
              1
            ],
            [
              13,
              0.75
            ],
            [
              18,
              0.5
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              11,
              "rgba(167, 209, 232, 0.75)"
            ],
            [
              13,
              "rgba(76, 147, 226, 0.75)"
            ],
            [
              20,
              "rgba(0, 140, 204, 0.75)"
            ]
          ]
        }
      },
      "id": "Waterway-Drain-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "waterway",
      "type": "line",
      "minzoom": 13,
      // "maxzoom": 21
      "maxzoom": ARIAL_SHOW_ZOOM

    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "dock"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              10,
              0.75
            ],
            [
              14,
              1.5
            ],
            [
              18,
              2
            ]
          ]
        },
        "line-color": "rgba(73, 73, 73, 1)"
      },
      "id": "Water-Dry-Dock-ln",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "line",
      "minzoom": 12,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "river"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-opacity": 1,
        "line-width": {
          "stops": [
            [
              13,
              1
            ],
            [
              18,
              0.75
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              13,
              "rgba(76, 147, 226, 0.75)"
            ],
            [
              20,
              "rgba(0, 140, 204, 0.75)"
            ]
          ]
        }
      },
      "id": "Water-River-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "waterway",
      "type": "line",
      "minzoom": 13,
      // "maxzoom": 21,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "river"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-opacity": 1,
        "line-width": {
          "stops": [
            [
              12,
              1.5
            ],
            [
              13,
              1
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              9,
              "rgba(125, 198, 215, 0.01)"
            ],
            [
              10,
              "rgba(125, 198, 215, 0.75)"
            ],
            [
              11,
              "rgba(167, 209, 232, 0.75)"
            ],
            [
              12,
              "rgba(167, 209, 232, 0.75)"
            ],
            [
              13,
              "rgba(76, 147, 226, 0.75)"
            ]
          ]
        }
      },
      "id": "Water-River-Ln-Named",
      "source": "LINZ Basemaps",
      "source-layer": "waterway",
      "type": "line",
      "minzoom": 8,
      // "maxzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "channel"
        ],
        [
          "==",
          "channel_type",
          "water_race"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": 0.5,
        "line-color": {
          "stops": [
            [
              13,
              "rgba(0, 140, 204, 0.3)"
            ],
            [
              19,
              "rgba(0, 140, 204, 1)"
            ]
          ]
        }
      },
      "id": "Waterway-WaterRace",
      "source": "LINZ Basemaps",
      "source-layer": "waterway",
      "type": "line",
      "maxzoom": ARIAL_SHOW_ZOOM,
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "landfill"
        ]
      ],
      "paint": {
        "fill-color": "rgba(199,188,178,0.45)",
        "fill-antialias": true
      },
      "id": "Landuse-Landfill",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "fill",
      "minzoom": 12,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "wetland"
        ],
        [
          "==",
          "wetland_feature",
          "pond"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-outline-color": {
          "stops": [
            [
              6,
              "rgba(184, 220, 242, 1)"
            ],
            [
              13,
              "rgba(0, 140, 204, 0.3)"
            ],
            [
              19,
              "rgba(0, 140, 204, 1)"
            ]
          ]
        }
      },
      "id": "Landuse-Pond",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "fill",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "reservoir"
        ],
        [
          "==",
          "lid_type",
          "covered"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(148, 148, 148, 1)",
        "fill-outline-color": "rgba(18, 18, 18, 1)"
      },
      "id": "Landuse-Reservoir-Covered",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "fill",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "reservoir"
        ],
        [
          "!=",
          "lid_type",
          "covered"
        ]
      ],
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)",
        "fill-outline-color": "rgba(18, 18, 18, 1)"
      },
      "id": "Landuse-Reservoir-Empty",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "fill",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "residential"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": {
          "stops": [
            [
              1,
              "rgba(204, 204, 204, 1)"
            ],
            [
              10,
              "rgba(204, 204, 204, 0.95)"
            ],
            [
              13,
              "rgba(204, 204, 204, 0.6)"
            ],
            [
              15,
              "rgba(232, 232, 232, 0.4)"
            ],
            [
              22,
              "rgba(232, 232, 232, 0.1)"
            ]
          ]
        },
        "fill-outline-color": "rgba(164, 164, 164, 0.01)"
      },
      "id": "Landuse-Residential",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "fill",
      "minzoom": 8,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "grass"
        ],
        [
          "==",
          "grass_type",
          "golf_course"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(194,194,194,0.2)"
      },
      "id": "Landcover-GolfCourse",
      "source": "LINZ Basemaps",
      "source-layer": "landcover",
      "type": "fill",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              6,
              0.5
            ],
            [
              10,
              0.55
            ],
            [
              15,
              1.25
            ],
            [
              20,
              3.5
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              1,
              "rgba(9, 132, 186, 0.5)"
            ],
            [
              6,
              "rgba(9, 132, 186, 0.5)"
            ],
            [
              10,
              "rgba(27, 152, 193, 0.5)"
            ],
            [
              16,
              "rgba(34, 164, 212, 0.5)"
            ]
          ]
        }
      },
      "id": "Coastline-Ln-2",
      "source": "LINZ Basemaps",
      "source-layer": "coastline",
      "type": "line",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "fish_farm"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": "rgba(228,248,255,0.5)"
      },
      "id": "Poi-FishFarm",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "fill",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "sports_field"
        ]
      ],
      "paint": {
        "fill-color": "rgba(121, 195, 128, 0.2)"
      },
      "id": "Poi-SportsField",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "fill",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "storage_tank"
        ],
        [
          "!has",
          "store_item"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": {
          "stops": [
            [
              6,
              "rgba(240, 240, 240, 0.85)"
            ],
            [
              10,
              "rgba(240, 240, 240, 0.85)"
            ]
          ]
        },
        "fill-opacity": {
          "stops": [
            [
              14,
              1
            ],
            [
              20,
              0.2
            ]
          ]
        },
        "fill-antialias": true,
        "fill-outline-color": "rgba(67, 67, 67, 1)"
      },
      "id": "Poi-Storage-Tank-Empty",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "fill",
      "minzoom": 0,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "siphon"
        ]
      ],
      "id": "Poi-Siphon",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "fill",
      "minzoom": 12,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "storage_tank_pt"
        ],
        [
          "!has",
          "store_item"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "circle-opacity": {
          "stops": [
            [
              14,
              1
            ],
            [
              20,
              0.2
            ]
          ]
        },
        "circle-radius": {
          "stops": [
            [
              12,
              1.5
            ],
            [
              15,
              3.5
            ]
          ]
        },
        "circle-color": "rgba(255, 255, 255, 1)",
        "circle-pitch-alignment": "map"
      },
      "id": "Poi-Tank-Pt-Fill-Empty",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "circle",
      "minzoom": 10,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "dredge_tailing"
        ]
      ],
      "paint": {
        "line-opacity": 0.9,
        "line-width": 10,
        "line-pattern": "dredge_tailing_pnt",
        "line-color": "rgba(55, 55, 55, 1)"
      },
      "id": "Poi-Dredge-Tailing",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "line",
      "minzoom": 12,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "slipway"
        ]
      ],
      "paint": {
        "line-width": {
          "stops": [
            [
              12,
              1
            ],
            [
              16,
              4
            ]
          ]
        },
        "line-color": "rgba(64, 64, 64, 1)"
      },
      "id": "Poi-Slipway-Symbol-Dash",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "line",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "slipway"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              12,
              4
            ],
            [
              16,
              18
            ]
          ]
        },
        "line-color": "rgba(64, 64, 64, 1)",
        "line-dasharray": [
          0.15,
          0.4
        ]
      },
      "id": "Poi-Slipway-Symbol-Line",
      "source": "LINZ Basemaps",
      "source-layer": "poi",
      "type": "line",
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "none",
        [
          "==",
          "parcel_intent",
          "Road"
        ],
        [
          "==",
          "parcel_intent",
          "Hydro"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              0.5
            ],
            [
              16,
              1
            ],
            [
              24,
              2
            ]
          ]
        },
        "line-color": "rgb(255,255,255)",
        "line-dasharray": [0, 4, 3],
      },
      "id": "Parcels-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "parcel_boundaries",
      "type": "line",
      "minzoom": 15
    },
    {
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "fill-color": {
          "stops": [
            [
              15,
              "#DBDBD9"
            ],
            [
              17,
              "#DBDBD9"
            ],
            [
              18,
              "#DBDBD9"
            ],
            [
              19,
              "#DBDBD9"
            ]
          ]
        },
        "fill-opacity": 1,
        "fill-antialias": true
      },
      "id": "Buildings",
      "source": "LINZ Basemaps",
      "source-layer": "building",
      "type": "fill",
      "minzoom": 16,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              17,
              0.5
            ],
            [
              24,
              1
            ]
          ]
        },
        "line-color": "rgba(152, 145, 145,0.6)"
      },
      "id": "Buildings-Outline",
      "source": "LINZ Basemaps",
      "source-layer": "building",
      "type": "line",
      "minzoom": 16,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "brunnel",
          "bridge"
        ],
        [
          "==",
          "use_1",
          "foot traffic"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              10,
              0.5
            ],
            [
              15,
              4
            ],
            [
              19,
              6
            ]
          ]
        },
        "line-color": "rgba(78, 78, 78, 0.3)"
      },
      "id": "Transport-Bridge-Foot",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 13,
      "maxzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "track"
        ],
        [
          "==",
          "track_use",
          "foot"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              13,
              1.5
            ],
            [
              15,
              2.5
            ],
            [
              19,
              5
            ]
          ]
        },
        "line-color": "rgb(255,255,255)",
      },
      "id": "Transport-FootTracks-Shadow",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "track"
        ],
        [
          "==",
          "track_use",
          "vehicle"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              13,
              3
            ],
            [
              15,
              4
            ],
            [
              19,
              6
            ]
          ],
          "base": 1
        },
        "line-blur": 0.75,
        "line-dasharray": [
          8
        ],
        "line-color": "rgb(255,255,255)",
      },

      "id": "Transport-VehicleTracks-Shadow",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": ARIAL_SHOW_ZOOM
    },
    {
      "filter": [
        "all",
        [
          "==",
          "lane_count",
          1
        ],
        [
          "!=",
          "class",
          "motorway"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "bevel"
      },
      "maxzoom": 18,
      "paint": {
        "line-width": {
          "stops": [
            [
              10,
              1.5
            ],
            [
              12,
              3
            ],
            [
              13,
              4.5
            ],
            [
              15,
              5.5
            ],
            [
              18,
              11
            ],
            [
              19,
              40
            ],
            [
              22,
              200
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              10,
              "rgba(78, 78, 78, .1)"
            ],
            [
              17,
              "rgba(78, 78, 78, .1)"
            ],
            [
              18,
              "rgba(96, 96, 96, .1)"
            ]
          ]
        }
      },
      "id": "Transport-1Casing",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "in",
          "lane_count",
          2,
          3,
          4,
          5,
          6,
          7,
          8
        ],
        [
          "!=",
          "class",
          "motorway"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "bevel"
      },
      "maxzoom": 18,
      "paint": {
        "line-width": {
          "stops": [
            [
              11,
              3.5
            ],
            [
              12,
              3.9
            ],
            [
              13,
              8
            ],
            [
              15,
              10
            ],
            [
              18,
              16
            ],
            [
              19,
              50
            ],
            [
              22,
              200
            ]
          ]
        },
        "line-color": {
          "stops": [
            [
              10,
              "rgba(78, 78, 78, 0.1)"
            ],
            [
              17,
              "rgba(78, 78, 78, 0.1)"
            ],
            [
              18,
              "rgba(96, 96, 96, 0.1)"
            ]
          ]
        }
      },
      "id": "Transport-2Casing",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "surface",
          "unmetalled"
        ],
        [
          "==",
          "lane_count",
          1
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              10,
              0.5
            ],
            [
              12,
              1
            ],
            [
              13,
              2
            ],
            [
              15,
              3
            ],
            [
              18,
              8
            ],
            [
              19,
              35
            ],
            [
              22,
              180
            ]
          ]
        },
        "line-gap-width": 0,
        "line-color": "rgba(255, 254, 252, 1)"
      },
      "id": "Transport-1UnMetalled",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "surface",
          "metalled"
        ],
        [
          "==",
          "lane_count",
          1
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              10,
              0.5
            ],
            [
              11,
              1
            ],
            [
              13,
              2
            ],
            [
              15,
              3
            ],
            [
              18,
              8
            ],
            [
              19,
              35
            ],
            [
              22,
              180
            ]
          ]
        },
        "line-gap-width": 0,
        "line-color": "rgba(255, 254, 252, 1)"
      },
      "id": "Transport-1Metalled-White",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 10
    },
    {
      "filter": [
        "all",
        [
          "==",
          "lane_count",
          1
        ],
        [
          "!=",
          "class",
          "motorway"
        ],
        [
          "==",
          "status",
          "under construction"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              10,
              0.5
            ],
            [
              11,
              1
            ],
            [
              13,
              2
            ],
            [
              15,
              3
            ],
            [
              18,
              8
            ],
            [
              19,
              35
            ],
            [
              22,
              180
            ]
          ]
        },
        "line-color": "rgba(96, 96, 96, 0.1)",
        "line-dasharray": [
          1,
          5
        ]
      },
      "id": "Transport-1-UnderCons",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 10
    },
    {
      "filter": [
        "all",
        [
          "==",
          "surface",
          "sealed"
        ],
        [
          "==",
          "lane_count",
          1
        ],
        [
          "!=",
          "class",
          "motorway"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "bevel"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              10,
              0.5
            ],
            [
              11,
              1
            ],
            [
              13,
              2
            ],
            [
              15,
              3
            ],
            [
              18,
              8
            ],
            [
              19,
              35
            ],
            [
              22,
              180
            ]
          ]
        },
        "line-gap-width": 0,
        "line-color": {
          "stops": [
            [
              10,
              "#fff"
            ],
            [
              17,
              "#fff"
            ],
            [
              19,
              "#fff"
            ]
          ]
        }
      },
      "id": "Transport-1Sealed",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "surface",
          "unmetalled"
        ],
        [
          "in",
          "lane_count",
          2,
          3,
          4,
          5,
          6,
          7
        ],
        [
          "!=",
          "staus",
          "under construction"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              10,
              1.35
            ],
            [
              11,
              1.75
            ],
            [
              13,
              4
            ],
            [
              16,
              7.5
            ],
            [
              18,
              14
            ],
            [
              19,
              55
            ],
            [
              22,
              380
            ]
          ]
        },
        "line-gap-width": 0,
        "line-color": "rgba(255, 255, 255, 1)"
      },
      "id": "Transport-2UnMetalled",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 10
    },
    {
      "filter": [
        "all",
        [
          "==",
          "surface",
          "metalled"
        ],
        [
          "in",
          "lane_count",
          2,
          3,
          4,
          5,
          6,
          7
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              10,
              1.35
            ],
            [
              11,
              1.75
            ],
            [
              13,
              4
            ],
            [
              15,
              7.5
            ],
            [
              18,
              14
            ],
            [
              19,
              55
            ],
            [
              22,
              380
            ]
          ]
        },
        "line-gap-width": 0,
        "line-color": "rgba(255, 255, 255, 1)"
      },
      "id": "Transport-2Metalled-White",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 10
    },
    {
      "filter": [
        "all",
        [
          "==",
          "status",
          "under construction"
        ],
        [
          "in",
          "lane_count",
          2,
          3,
          4,
          5,
          6,
          7
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              10,
              1.35
            ],
            [
              11,
              1.75
            ],
            [
              13,
              4
            ],
            [
              16,
              7.5
            ],
            [
              18,
              14
            ],
            [
              19,
              55
            ],
            [
              22,
              380
            ]
          ]
        },
        "line-gap-width": 0,
        "line-dasharray": [
          1,
          5
        ],
        "line-color": "rgba(96, 96, 96, 0.1)"
      },
      "id": "Transport-2UnderContruction",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 10
    },
    {
      "filter": [
        "all",
        [
          "==",
          "surface",
          "sealed"
        ],
        [
          "in",
          "lane_count",
          2,
          3,
          4,
          5,
          6,
          7
        ],
        [
          "!=",
          "class",
          "motorway"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-translate-anchor": "map",
        "line-width": {
          "stops": [
            [
              10,
              1
            ],
            [
              11,
              1
            ],
            [
              12,
              1.75
            ],
            [
              13,
              4
            ],
            [
              15,
              7.5
            ],
            [
              18,
              14
            ],
            [
              19,
              55
            ],
            [
              22,
              380
            ]
          ]
        },
        "line-gap-width": 0,
        "line-color": "rgba(255, 255, 255, 1)"
      },
      "id": "Transport-2+Sealed",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 10
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "rail"
        ],
        [
          "has",
          "rway_use"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-width": 1,
        "line-color": "rgba(180,171,171,0.65)"
      },
      "id": "Transport-Railway-Siding",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "rail"
        ],
        [
          "==",
          "track_type",
          "single"
        ],
        [
          "!has",
          "rway_use"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-width": 2,
        "line-color": "rgba(176,164,164,0.65)"
      },
      "id": "Transport-Railway-Single",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "rail"
        ],
        [
          "==",
          "track_type",
          "multiple"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              11,
              2.5
            ],
            [
              19,
              4
            ],
            [
              20,
              4
            ]
          ]
        },
        "line-color": "rgba(164,134,134,0.65)"
      },
      "id": "Transport-Railway-Multiple",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "rail"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "butt"
      },
      "maxzoom": 11,
      "paint": {
        "line-width": 2,
        "line-color": {
          "stops": [
            [
              8,
              "rgb(204,196,196)"
            ],
            [
              10,
              "rgba(169,147,147,0.95)"
            ]
          ]
        }
      },
      "id": "Transport-Railway-High",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ]
      ],
      "layout": {
        "visibility": "visible"
      },
      "maxzoom": 9,
      "paint": {
        "line-width": {
          "stops": [
            [
              1,
              0
            ],
            [
              6,
              0.5
            ],
            [
              7,
              1
            ],
            [
              8,
              3.5
            ]
          ]
        },
        "line-color": "rgb(199,199,199)"
      },
      "id": "Transport-Roads-9-Casing",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 0
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "round",
        "line-join": "round"
      },
      "maxzoom": 9,
      "paint": {
        "line-width": {
          "stops": [
            [
              1,
              0.1
            ],
            [
              5,
              0.4
            ],
            [
              7,
              1.4
            ],
            [
              8,
              2.5
            ]
          ]
        },
        "line-color": "rgb(229,213,187)"
      },
      "id": "Transport-Roads-9",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 0
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          1
        ]
      ],
      "layout": {
        "visibility": "none",
        "line-join": "round",
        "line-cap": "butt"
      },
      "maxzoom": 13,
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              1.5
            ],
            [
              10,
              1.75
            ],
            [
              12,
              3.25
            ],
            [
              13,
              5
            ],
            [
              15,
              7
            ],
            [
              18,
              13
            ],
            [
              19,
              50
            ],
            [
              22,
              220
            ]
          ]
        },
        "line-color": "rgb(176,176,176)"
      },
      "id": "Transport-1HWY-Casing",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          2,
          3
        ]
      ],
      "layout": {
        "visibility": "none",
        "line-join": "round",
        "line-cap": "butt"
      },
      "maxzoom": 13,
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              3.25
            ],
            [
              10,
              4.5
            ],
            [
              12,
              6.5
            ],
            [
              13,
              8.5
            ],
            [
              15,
              12
            ],
            [
              18,
              19
            ],
            [
              19,
              70
            ],
            [
              22,
              450
            ]
          ]
        },
        "line-color": "rgba(180,180,180,0.8)"
      },
      "id": "Transport-2HWY-Casing",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          4,
          5,
          6,
          7
        ]
      ],
      "layout": {
        "visibility": "none",
        "line-join": "round",
        "line-cap": "butt"
      },
      "maxzoom": 13,
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              3.5
            ],
            [
              10,
              5
            ],
            [
              12,
              7.5
            ],
            [
              13,
              9
            ],
            [
              15,
              13
            ],
            [
              18,
              21
            ],
            [
              19,
              80
            ],
            [
              22,
              470
            ]
          ]
        },
        "line-color": "rgb(173,173,173)"
      },
      "id": "Transport-HWY-Casing",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          2,
          3
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              3.25
            ],
            [
              10,
              4.5
            ],
            [
              12,
              6.5
            ],
            [
              13,
              8.5
            ],
            [
              15,
              12
            ],
            [
              18,
              19
            ],
            [
              19,
              70
            ],
            [
              22,
              450
            ]
          ]
        },
        "line-color": "rgb(206,206,206)"
      },
      "id": "Transport-2HWY-Casing-14",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 13
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          1
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "butt"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              1.5
            ],
            [
              10,
              1.75
            ],
            [
              12,
              3.25
            ],
            [
              13,
              5
            ],
            [
              15,
              7
            ],
            [
              18,
              13
            ],
            [
              19,
              50
            ],
            [
              22,
              220
            ]
          ]
        },
        "line-color": "rgb(190,190,190)"
      },
      "id": "Transport-1HWY-Casing-14",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 13
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          4,
          5,
          6,
          7
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "butt"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              3.5
            ],
            [
              10,
              5
            ],
            [
              12,
              7.5
            ],
            [
              13,
              9
            ],
            [
              15,
              13
            ],
            [
              18,
              21
            ],
            [
              19,
              80
            ],
            [
              22,
              470
            ]
          ]
        },
        "line-color": "rgb(178,178,178)"
      },
      "id": "Transport-HWY-Casing-14",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 13
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          2,
          3
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "round"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              1.35
            ],
            [
              10,
              1.75
            ],
            [
              11,
              2.25
            ],
            [
              13,
              5.25
            ],
            [
              15,
              8.5
            ],
            [
              18,
              15
            ],
            [
              19,
              60
            ],
            [
              22,
              420
            ]
          ]
        },
        "line-blur": 0,
        "line-color": "rgba(236,204,169,0.4)"
      },
      "id": "Transport-2HWY",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "==",
          "lane_count",
          1
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "butt"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              0.75
            ],
            [
              10,
              1
            ],
            [
              11,
              1.25
            ],
            [
              13,
              2.5
            ],
            [
              15,
              4
            ],
            [
              18,
              9
            ],
            [
              19,
              40
            ],
            [
              22,
              200
            ]
          ]
        },
        "line-color": "rgb(220,209,191)"
      },
      "id": "Transport-1HWY",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "motorway"
        ],
        [
          "in",
          "lane_count",
          4,
          5,
          6,
          7
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-join": "round",
        "line-cap": "butt"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              8,
              1.5
            ],
            [
              10,
              2
            ],
            [
              11,
              3
            ],
            [
              13,
              5.75
            ],
            [
              15,
              9
            ],
            [
              18,
              16.5
            ],
            [
              19,
              70
            ],
            [
              22,
              440
            ]
          ]
        },
        "line-color": "rgb(220,209,191)"
      },
      "id": "Transport-HWY",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "==",
          "brunnel",
          "bridge"
        ],
        [
          "in",
          "use_1",
          "train",
          "vehicle"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              12,
              6
            ],
            [
              15,
              8
            ],
            [
              18,
              18
            ],
            [
              19,
              75
            ],
            [
              22,
              450
            ]
          ]
        },
        "line-color": "rgb(199,199,199)"
      },
      "id": "Transport-Bridge-VT-Casing",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "brunnel",
          "bridge"
        ],
        [
          "in",
          "use_1",
          "train",
          "vehicle"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-width": {
          "stops": [
            [
              12,
              5
            ],
            [
              15,
              5
            ],
            [
              18,
              13
            ],
            [
              19,
              66
            ],
            [
              22,
              400
            ]
          ]
        },
        "line-color": "rgba(220, 220, 220, 1)"
      },
      "id": "Transport-Bridge-VT",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "boom"
        ]
      ],
      "paint": {
        "line-width": {
          "stops": [
            [
              10,
              1.5
            ],
            [
              13,
              2
            ],
            [
              15,
              4
            ]
          ]
        },
        "line-color": "rgb(110,110,110)"
      },
      "id": "Landuse-Boom",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "line",
      "minzoom": 12
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "dam_ln"
        ]
      ],
      "paint": {
        "line-width": {
          "stops": [
            [
              10,
              4
            ],
            [
              15,
              8.5
            ],
            [
              19,
              14.5
            ]
          ]
        },
        "line-color": "rgba(211, 211, 211, 1)"
      },
      "id": "Landuse-Dam",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "line"
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "marine_farm_ln"
        ]
      ],
      "paint": {
        "line-width": 3,
        "line-color": "rgba(25, 114, 242, 0.45)"
      },
      "id": "Landuse-MarineFarm-Ln",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "line"
    },
    {
      "filter": [
        "all",
        [
          "==",
          "brunnel",
          "tunnel"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "line-cap": "butt",
        "line-join": "bevel"
      },
      "paint": {
        "line-opacity": 1,
        "line-width": {
          "stops": [
            [
              10,
              0.5
            ],
            [
              15,
              1
            ],
            [
              19,
              4
            ]
          ]
        },
        "line-blur": 0,
        "line-gap-width": 8,
        "line-dasharray": [
          4,
          2.5
        ],
        "line-color": "rgba(90, 89, 86, 0.6)"
      },
      "id": "Transport-Tunnel-VT",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "line",
      "minzoom": 10
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "gravel_pit"
        ]
      ],
      "layout": {
        "icon-size": 0.8,
        "text-field": "",
        "text-font": [
          "Open Sans Regular"
        ],
        "text-size": {
          "stops": [
            [
              12,
              6
            ],
            [
              15,
              10
            ]
          ]
        }
      },
      "id": "Landuse-GravelPit-Label",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "symbol",
      "minzoom": 12
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "marine_farm"
        ]
      ],
      "layout": {
        "text-field": "{species}",
        "text-font": [
          "Roboto Bold Italic"
        ]
      },
      "paint": {
        "text-halo-width": 1.5,
        "text-color": "rgba(232, 232, 232, 1)",
        "text-halo-color": "rgba(25, 114, 242, 0.45)"
      },
      "id": "Landuse-MarineFarm-Label",
      "source": "LINZ Basemaps",
      "source-layer": "landuse",
      "type": "symbol",
      "minzoom": 15
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "stream"
        ],
        [
          "==",
          "stream_feature",
          "rapid_cl"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "symbol-spacing": 750,
        "visibility": "visible",
        "text-field": "{name}",
        "icon-anchor": "center",
        "text-size": 12,
        "text-anchor": "bottom",
        "symbol-placement": "line",
        "text-font": [
          "Open Sans Italic"
        ]
      },
      "paint": {
        "text-halo-blur": 1,
        "text-color": "rgba(0, 140, 204, 1)",
        "text-halo-color": "rgba(239, 239, 239, 0.80)",
        "text-halo-width": 1.5
      },
      "id": "Waterway-Rapid-Names",
      "source": "LINZ Basemaps",
      "source-layer": "waterway",
      "type": "symbol",
      "minzoom": 13
    },
    {
      "filter": [
        "all",
        [
          "has",
          "hway_num"
        ],
        [
          "!in",
          "hway_num",
          "6,94",
          "2,50",
          "1,3",
          "12,15",
          "14,15",
          "6,96",
          "25A",
          "20A",
          "20B",
          "29A",
          "30A",
          "74A",
          "67A"
        ],
        [
          "!=",
          "lane_count",
          1
        ]
      ],
      "layout": {
        "icon-rotation-alignment": "viewport",
        "icon-pitch-alignment": "viewport",
        "visibility": "visible",
        "text-field": "{hway_num}",
        "text-size": {
          "stops": [
            [
              8,
              9
            ],
            [
              11,
              10
            ],
            [
              15,
              14
            ]
          ]
        },
        "icon-text-fit": "none",
        "icon-size": {
          "stops": [
            [
              8,
              1.2
            ],
            [
              12,
              1.5
            ],
            [
              15,
              1.7
            ]
          ]
        },
        "symbol-placement": "line",
        "icon-image": "highway_pnt_standard",
        "text-font": [
          "Open Sans Bold"
        ],
        "icon-padding": 2,
        "symbol-spacing": {
          "stops": [
            [
              6,
              500
            ],
            [
              13,
              400
            ]
          ]
        },
        "text-pitch-alignment": "viewport",
        "text-justify": "center",
        "icon-text-fit-padding": [
          1,
          4,
          3,
          3
        ],
        "icon-anchor": "center",
        "text-rotation-alignment": "viewport",
        "icon-offset": [
          0,
          1
        ],
        "text-keep-upright": true,
        "icon-keep-upright": false,
        "icon-rotate": 0
      },
      "paint": {
        "icon-translate-anchor": "map",
        "text-color": "rgba(255, 255, 255, 1)",
        "text-translate-anchor": "map",
        "icon-opacity": {
          "stops": [
            [
              6,
              0.9
            ],
            [
              14,
              1
            ]
          ]
        }
      },
      "id": "All-Highway-Labels-standard",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "symbol",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "in",
          "hway_num",
          "25A",
          "20A",
          "20B",
          "29A",
          "30A",
          "74A",
          "67A"
        ]
      ],
      "layout": {
        "icon-rotation-alignment": "viewport",
        "icon-pitch-alignment": "viewport",
        "visibility": "visible",
        "text-field": "{hway_num}",
        "text-size": {
          "stops": [
            [
              8,
              9
            ],
            [
              11,
              10
            ],
            [
              15,
              14
            ]
          ]
        },
        "icon-text-fit": "none",
        "icon-size": {
          "stops": [
            [
              8,
              1.2
            ],
            [
              12,
              1.5
            ],
            [
              15,
              1.7
            ]
          ]
        },
        "symbol-placement": "line",
        "icon-image": "highway_pnt_wide",
        "text-font": [
          "Open Sans Bold"
        ],
        "icon-padding": 2,
        "symbol-spacing": {
          "stops": [
            [
              6,
              500
            ],
            [
              13,
              400
            ]
          ]
        },
        "text-pitch-alignment": "viewport",
        "text-justify": "center",
        "icon-text-fit-padding": [
          1,
          4,
          3,
          3
        ],
        "icon-anchor": "center",
        "text-rotation-alignment": "viewport",
        "icon-offset": [
          0,
          1
        ],
        "text-keep-upright": true,
        "icon-keep-upright": false,
        "icon-rotate": 0
      },
      "paint": {
        "icon-translate-anchor": "map",
        "text-color": "rgba(255, 255, 255, 1)",
        "text-translate-anchor": "map",
        "icon-opacity": {
          "stops": [
            [
              6,
              0.9
            ],
            [
              14,
              1
            ]
          ]
        }
      },
      "id": "All-Highway-Labels-three",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "symbol",
      "minzoom": 8
    },
    {
      "filter": [
        "all",
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "symbol-spacing": 250,
        "text-transform": "none",
        "icon-pitch-alignment": "auto",
        "visibility": "visible",
        "text-justify": "center",
        "text-field": "{name}",
        "text-size": {
          "stops": [
            [
              13,
              8
            ],
            [
              15,
              8
            ],
            [
              18,
              10
            ],
            [
              19,
              10
            ],
            [
              22,
              60
            ]
          ]
        },
        "text-anchor": "center",
        "icon-text-fit": "both",
        "symbol-placement": "line",
        "icon-ignore-placement": false,
        "text-font": [
          "Open Sans Regular"
        ]
      },
      "paint": {
        "text-halo-color": "rgba(243, 243, 242, 0.9)",
        "text-color": "rgba(51, 51, 51, 1)",
        "text-halo-width": 2.5,
        "text-opacity": 0.9
      },
      "id": "All-Road-Labels",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "symbol",
      "minzoom": 13
    },
    {
      "filter": [
        "all",
        [
          "==",
          "brunnel",
          "tunnel"
        ]
      ],
      "layout": {
        "symbol-spacing": 180,
        "visibility": "visible",
        "text-field": "{brunnel}",
        "text-offset": {
          "stops": [
            [
              15,
              [
                0,
                -1.25
              ]
            ],
            [
              17,
              [
                0,
                -1.75
              ]
            ],
            [
              19,
              [
                0,
                -6
              ]
            ],
            [
              20,
              [
                0,
                -9
              ]
            ],
            [
              22,
              [
                0,
                -16
              ]
            ]
          ]
        },
        "text-size": 10,
        "symbol-placement": "line",
        "text-font": [
          "Roboto Black"
        ]
      },
      "paint": {
        "text-halo-width": 2,
        "text-color": "rgba(80, 75, 75, 1)",
        "text-halo-color": "rgba(230, 230, 230, 0.5)"
      },
      "id": "Transport-Tunnel-Label",
      "source": "LINZ Basemaps",
      "source-layer": "transportation",
      "type": "symbol",
      "minzoom": 15
    },
    {
      "layout": {
        "icon-rotation-alignment": "viewport",
        "icon-pitch-alignment": "viewport",
        "visibility": "visible",
        "text-field": "{name}",
        "text-size": 10,
        "text-anchor": "top",
        "text-allow-overlap": false,
        "icon-text-fit": "none",
        "icon-size": 1.2,
        "icon-ignore-placement": false,
        "icon-image": "airport_airport_pnt_fill",
        "text-font": [
          "Open Sans Bold Italic"
        ],
        "symbol-spacing": 250,
        "text-pitch-alignment": "auto",
        "text-justify": "center",
        "text-max-width": 5,
        "icon-anchor": "bottom",
        "text-rotation-alignment": "auto",
        "icon-offset": [
          0,
          0
        ],
        "text-padding": 2
      },
      "paint": {
        "text-halo-blur": 0.5,
        "text-color": "rgba(129, 123, 123, 1)",
        "text-halo-color": "rgba(255, 255, 255, 1)",
        "text-halo-width": 1,
        "icon-opacity": 0.8
      },
      "id": "Airport-Label",
      "source": "LINZ Basemaps",
      "source-layer": "aerodrome_label",
      "type": "symbol",
      "minzoom": 12
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "runway"
        ],
        [
          "!has",
          "surface"
        ]
      ],
      "layout": {
        "text-justify": "left",
        "visibility": "visible",
        "text-field": "airstrip",
        "text-font": [
          "Roboto Light"
        ],
        "text-size": 10,
        "text-anchor": "left"
      },
      "paint": {
        "text-halo-width": 1.5,
        "text-halo-blur": 0.5,
        "text-halo-color": "rgba(246, 246, 246, 1)"
      },
      "id": "Aeroway-Airstrip-Label",
      "source": "LINZ Basemaps",
      "source-layer": "aeroway",
      "type": "symbol",
      "minzoom": 15
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "reef"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "text-max-width": 4,
        "text-field": "{name}",
        "text-offset": [
          0,
          1
        ],
        "text-size": 12,
        "text-anchor": "center",
        "text-font": [
          "Open Sans Italic"
        ]
      },
      "paint": {
        "text-halo-width": 2,
        "text-color": "rgba(0, 140, 204, 1)",
        "text-halo-color": "rgba(239, 239, 239, 1)"
      },
      "id": "All-Reef-Names",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "symbol",
      "minzoom": 13
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "lake"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "visibility": "visible",
        "text-max-width": 4,
        "text-field": "{name}",
        "text-font": [
          "Open Sans Italic"
        ],
        "text-size": 12
      },
      "paint": {
        "text-halo-width": 2,
        "text-color": "rgba(0, 140, 204, 1)",
        "text-halo-color": "rgba(239, 239, 239, 1)"
      },
      "id": "All-Lake-Names",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "symbol",
      "minzoom": 11
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "river"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "icon-allow-overlap": false,
        "visibility": "visible",
        "text-field": "{name}",
        "text-size": 12,
        "text-anchor": "bottom",
        "text-allow-overlap": true,
        "symbol-placement": "line",
        "icon-ignore-placement": false,
        "text-font": [
          "Open Sans Italic"
        ],
        "symbol-spacing": 1000,
        "text-justify": "center",
        "icon-anchor": "center",
        "text-ignore-placement": true
      },
      "paint": {
        "text-halo-blur": 1,
        "text-color": "rgba(0, 140, 204, 1)",
        "text-halo-color": "rgba(239, 239, 239, 0.80)",
        "text-halo-width": 1.5
      },
      "id": "All-River-Names",
      "source": "LINZ Basemaps",
      "source-layer": "water",
      "type": "symbol",
      "minzoom": 12
    },
    {
      "filter": [
        "all",
        [
          "==",
          "class",
          "river"
        ],
        [
          "has",
          "name"
        ]
      ],
      "layout": {
        "symbol-spacing": 1000,
        "text-field": "{name}",
        "text-size": 12,
        "text-anchor": "bottom",
        "text-ignore-placement": true,
        "text-allow-overlap": true,
        "symbol-placement": "line",
        "text-font": [
          "Open Sans Italic"
        ]
      },
      "paint": {
        "text-halo-blur": 1,
        "text-color": "rgba(0, 140, 204, 1)",
        "text-halo-color": "rgba(239, 239, 239, 0.80)",
        "text-halo-width": 1.5
      },
      "id": "All-Waterway-River-Names",
      "source": "LINZ Basemaps",
      "source-layer": "waterway",
      "type": "symbol",
      "minzoom": 12
    },
    {
      "layout": {
        "text-allow-overlap": false,
        "text-field": "{housenumber}",
        "text-font": [
          "Open Sans Regular"
        ],
        "text-size": {
          "stops": [
            [
              17,
              9
            ],
            [
              19,
              10
            ]
          ]
        },
        "text-anchor": "bottom"
      },
      "paint": {
        "icon-color": "rgba(0, 0, 0, 0.6)",
        "text-halo-blur": 20,
        "text-color": "rgba(85, 85, 85, 1)",
        "text-halo-color": "rgba(255, 255, 255, 0.8)",
        "text-halo-width": 0.5
      },
      "id": "Housenumber",
      "source": "LINZ Basemaps",
      "source-layer": "housenumber",
      "type": "symbol",
      "minzoom": 17
    },
    {
      "layout": {
        "icon-rotation-alignment": "auto",
        "text-variable-anchor": [
          "top",
          "bottom-left"
        ],
        "text-optional": true,
        "visibility": "visible",
        "text-field": "{name}",
        "text-size": {
          "stops": [
            [
              2,
              10
            ],
            [
              5,
              16
            ],
            [
              8,
              16
            ],
            [
              10,
              17
            ],
            [
              12,
              16
            ],
            [
              14,
              16
            ]
          ]
        },
        "text-allow-overlap": true,
        "icon-text-fit": "both",
        "text-font": [
          "Roboto Regular"
        ],
        "text-pitch-alignment": "viewport",
        "text-max-width": 7,
        "text-letter-spacing": {
          "stops": [
            [
              10,
              0.08
            ],
            [
              13,
              0.04
            ],
            [
              14,
              0
            ]
          ]
        },
        "text-offset": {
          "stops": [
            [
              4,
              [
                0,
                1.75
              ]
            ],
            [
              6,
              [
                0,
                1.25
              ]
            ]
          ]
        },
        "text-rotation-alignment": "viewport",
        "text-ignore-placement": false
      },
      "paint": {
        "text-halo-color": "rgba(255, 252, 252, 1)",
        "icon-halo-width": {
          "stops": [
            [
              13,
              1
            ],
            [
              14,
              2
            ]
          ]
        },
        "text-translate-anchor": "viewport",
        "icon-halo-color": "rgba(255, 255, 255, 1)",
        "icon-color": {
          "stops": [
            [
              6,
              "rgba(53, 53, 53, 1)"
            ],
            [
              10,
              "rgba(53, 53, 53, 1)"
            ]
          ]
        },
        "text-halo-blur": 1,
        "text-color": {
          "stops": [
            [
              6,
              "rgba(35, 34, 34, 1)"
            ],
            [
              19,
              "rgba(111, 111, 111, 1)"
            ]
          ]
        },
        "text-halo-width": {
          "stops": [
            [
              4,
              1.5
            ],
            [
              9,
              2
            ]
          ]
        },
        "icon-halo-blur": {
          "stops": [
            [
              13,
              1
            ],
            [
              14,
              0.5
            ]
          ]
        },
        "icon-opacity": 1
      },
      "id": "Place-Names-13",
      "source": "LINZ Basemaps",
      "source-layer": "place",
      "type": "symbol",
      "minzoom": 13
    },
    {
      "layout": {
        "text-variable-anchor": [
          "top",
          "bottom-left"
        ],
        "icon-pitch-alignment": "auto",
        "text-optional": true,
        "visibility": "visible",
        "text-field": "{name}",
        "text-size": {
          "stops": [
            [
              2,
              10
            ],
            [
              5,
              16
            ],
            [
              8,
              16
            ],
            [
              10,
              17
            ],
            [
              12,
              17
            ],
            [
              14,
              17
            ]
          ]
        },
        "text-font": [
          "Roboto Regular"
        ],
        "text-pitch-alignment": "viewport",
        "text-letter-spacing": {
          "stops": [
            [
              10,
              0.08
            ],
            [
              13,
              0.04
            ],
            [
              14,
              0
            ]
          ]
        },
        "text-max-width": 6,
        "text-offset": {
          "stops": [
            [
              4,
              [
                0,
                1.75
              ]
            ],
            [
              6,
              [
                0,
                1.25
              ]
            ]
          ]
        },
        "text-rotation-alignment": "viewport",
        "text-ignore-placement": false
      },
      "maxzoom": 13,
      "paint": {
        "icon-halo-width": {
          "stops": [
            [
              13,
              1
            ],
            [
              14,
              2
            ]
          ]
        },
        "text-halo-color": "rgba(255, 252, 252, 1)",
        "icon-halo-color": "rgba(255, 255, 255, 1)",
        "icon-color": {
          "stops": [
            [
              6,
              "rgba(53, 53, 53, 1)"
            ],
            [
              10,
              "rgba(53, 53, 53, 1)"
            ]
          ]
        },
        "icon-translate-anchor": "viewport",
        "text-halo-blur": 1,
        "text-color": {
          "stops": [
            [
              6,
              "rgba(35, 34, 34, 1)"
            ],
            [
              19,
              "rgba(111, 111, 111, 1)"
            ]
          ]
        },
        "icon-opacity": 1,
        "icon-halo-blur": {
          "stops": [
            [
              13,
              1
            ],
            [
              14,
              0.5
            ]
          ]
        },
        "text-halo-width": {
          "stops": [
            [
              4,
              1.5
            ],
            [
              9,
              2
            ]
          ]
        }
      },
      "id": "Place-Names-3-12",
      "source": "LINZ Basemaps",
      "source-layer": "place",
      "type": "symbol",
      "minzoom": 3
    },
    {
      "layout": {
        "visibility": "visible"
      },
      "paint": {
        "raster-brightness-min": 0,
        "raster-opacity": {
          "stops": [
            [
              1,
              0.55
            ],
            [
              7,
              0.55
            ],
            [
              8,
              0.85
            ],
            [
              15,
              0.85
            ],
            [
              16,
              0.5
            ]
          ]
        },
        "raster-resampling": "nearest",
        "raster-contrast": 0
      },
      "id": "texture-relief-combined-test",
      "source": "LINZ-Texture-Relief",
      "type": "raster"
    },
  ],
  "metadata": {
    "maputnik:renderer": "mbgljs"
  },
  "glyphs": "https://basemaps.linz.govt.nz/v1/fonts/{fontstack}/{range}.pbf",
  "sprite": "https://basemaps.linz.govt.nz/v1/sprites/topographic"
})