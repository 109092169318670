/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getParkReview = /* GraphQL */ `query GetParkReview($id: ID!) {
  getParkReview(id: $id) {
    id
    parkId
    title
    description
    rating
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetParkReviewQueryVariables,
  APITypes.GetParkReviewQuery
>;
export const listParkReviews = /* GraphQL */ `query ListParkReviews(
  $filter: ModelParkReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listParkReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      parkId
      title
      description
      rating
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListParkReviewsQueryVariables,
  APITypes.ListParkReviewsQuery
>;
