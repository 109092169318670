import {AppNavigator} from "../../components/AppNavigator";
import React, {useEffect, useMemo} from "react";
import {Autocomplete, Box, IconButton, TextField} from "@mui/material";
import data from "data/searchData.json";
import './SearchPage.css'
import {createSearchParams, Outlet, useNavigate, useParams, useSearchParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {userPool} from "../../userpool";


export function SearchPage() {
  const options = data.map(park => ({label: park.name, id: park.id, location: park.searchArea}))
  const {parkId} = useParams()
  const [params] = useSearchParams()

  const location = useMemo(() => {
    const lat = params.get("lat")
    const lon = params.get("lon")
    const zoom = params.get("zoom")

    if (lat && lon && zoom) {
      return {lat: parseFloat(lat), lon: parseFloat(lon), zoom: parseFloat(zoom)}
    }
  }, [params])
  const selectedOption = useMemo(() => {
    return options.find(option => option.id === parkId)
  }, [parkId, options])
  const navigate = useNavigate()
  const user = userPool.getCurrentUser();

  useEffect(() => {
    if (user === null) {
      navigate("/")
    }
  }, [user, navigate])

  return <div className={"SearchPage"}>
    <div className={"SearchPageContent"}>
      <div className={"SearchPage-topSearchNav"}>
        {selectedOption && location &&
            <IconButton
                aria-label="delete"
                size={'large'}
                onClick={() =>
                  navigate({
                    pathname: '/map',
                    search: `${createSearchParams({
                      lat: `${location.lat}`,
                      lon: `${location.lon}`,
                      zoom: `${location.zoom}`
                    })}`,
                  })
                }
            >
                <ArrowBackIcon fontSize={"large"}/>
            </IconButton>}
        <Autocomplete
          defaultValue={selectedOption}
          renderInput={(params) => <TextField {...params} label="Search"/>}
          renderOption={
            (props, option) =>
              <Box {...props} component="li" key={option.id} onClick={(e) => {
                navigate(option.id)
                props.onClick && props.onClick(e)
              }}>{option.label}</Box>
          }
          options={options}
          className={"SearchPage-searchBar"}
        />
      </div>
      <Outlet/>
    </div>
    <div className={'SearchPageNavBar'}>
      <AppNavigator overRides={{path: "/search"}}/>
    </div>
  </div>
}