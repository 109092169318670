import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import HomeIcon from '@mui/icons-material/Home';
import React from "react";
import {useNavigate, useLocation} from "react-router-dom";
import "./AppNavigator.css"

export function AppNavigator({overRides}:{overRides?: { path: string }}) {
  const navigate = useNavigate()
  const location = useLocation()

  return <BottomNavigation
    showLabels
    value={overRides?.path || location.pathname}
    onChange={(event, newValue) => {
      navigate(newValue)
    }}
    className={"AppNavigator"}
  >
    <BottomNavigationAction value={'/'} label="Home" icon={<HomeIcon/>}/>
    <BottomNavigationAction value={'/map'} label="Map" icon={<MapIcon/>}/>
    <BottomNavigationAction value={'/search'} label="Search" icon={<SearchIcon/>}/>
    <BottomNavigationAction value={'/account'} label="Account" icon={<PersonIcon/>}/>
  </BottomNavigation>
}