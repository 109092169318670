import React, {useCallback, useEffect, useMemo} from 'react';
import type {FillLayer} from 'react-map-gl';
import Map, {FullscreenControl, Layer, Source} from 'react-map-gl';
import './MapPage.css';
import {AppNavigator} from "components/AppNavigator";
import data from "data/geoJson.json";
import {getStyleJson} from "data/styleJson"
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";
import {userPool} from "../../userpool";
import {LineLayer, StyleFunction} from "mapbox-gl";

const parksColorStyleFunction: StyleFunction = {
  property: 'restriction',
  stops: [
    [0, '#006704'],
    [1, '#03c788'],
    [2, '#0067d2'],
    [3, '#cbbb00'],
    [5, '#e37200'],
    [10, '#d50024'],
    [100, '#000']],
  default: '#000',
}

export function MapPage() {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const user = userPool.getCurrentUser();

  useEffect(() => {
    if (user === null) {
      navigate("/")
    }
  }, [user])

  const onClick = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    const {
      features, lngLat, target
    } = event;
    const clickedFeature = features && features[0];

    if (clickedFeature?.properties?.id) {
      navigate({
        pathname: `/search/${clickedFeature.properties.id}`, search: `${createSearchParams({
          lat: `${lngLat.lat}`, lon: `${lngLat.lng}`, zoom: `${target.getZoom()}`,
        })}`,
      })
    }
  }, [navigate]);

  const location = useMemo(() => {
    const lat = params.get("lat")
    const lon = params.get("lon")
    const zoom = params.get("zoom")

    if (lat && lon && zoom) {
      return {lat: parseFloat(lat), lon: parseFloat(lon), zoom: parseFloat(zoom)}
    }
  }, [params])

  // For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
  const dataLayer: FillLayer = {
    id: 'data', type: 'fill', paint: {
      'fill-color': parksColorStyleFunction,
      'fill-opacity': 0.2
    }
  };

  const dataLineLayer: LineLayer = {
    id: 'dataLine', type: 'line', paint: {
      'line-color': parksColorStyleFunction,
      'line-opacity': 0.8,
      "line-width": [
        'interpolate',
        // Set the exponential rate of change to 0.5
        ['exponential', 0.5],
        ['zoom'],
        // When zoom is 10, buildings will be 100% transparent.
        14,
        2,
        // When zoom is 18 or higher, buildings will be 100% opaque.
        18,
        10
      ]
    }
  };

  return (<>
    <div className="MapPage">
      <Map
        terrain={{source: "basemaps-elevation-terrain-rgb-dem", exaggeration: 1.5}}
        mapboxAccessToken={process.env.REACT_APP_MAP_BOX_KEY}
        initialViewState={location ? {
          longitude: location.lon, latitude: location.lat, zoom: location.zoom
        } : {
          longitude: 174.845749, latitude: -41.182734, zoom: 10
        }}
        style={{width: '100vw', height: '100vh'}}
        mapStyle={getStyleJson(process.env.REACT_APP_BASEMAPS_API_KEY || "")}
        onRender={(event) => event.target.resize()}
        interactiveLayerIds={['data']}
        onClick={onClick}
      >
        <FullscreenControl/>
        {  /* @ts-ignore */}
        <Source type="geojson" data={data}>
          <Layer {...dataLineLayer}/>
          <Layer {...dataLayer}/>
        </Source>
      </Map>

    </div>
    <div className={"MapPageNavBar"}>
      <AppNavigator/>
    </div>
  </>);
}
