import { useParams, useSearchParams } from "react-router-dom";
import data from "data/searchData.json";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, CardContent, Paper, Rating, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import "./SearchResult.css"
import { ReviewSection } from "components/review/ReviewSecton";
import { ParkReview, ListParkReviewsQuery } from "API";
import { generateClient, GraphQLResult } from "aws-amplify/api";
import { listParkReviews } from "graphql/queries";


export function SearchResult() {
  const { parkId } = useParams()
  const client = generateClient()
  const [params] = useSearchParams()
  const option = useMemo(() => data.find(v => v.id === parkId), [parkId])
  const location = useMemo(() => {
    const lat = params.get("lat")
    const lon = params.get("lon")
    if (lat && lon) {
      return { lat: parseFloat(lat), lon: parseFloat(lon) }
    }
  }, [params])

  const [averageRating, setAverageRating] = useState<number | null>(null)

  useEffect(() => {
      const reviewsPromise = client.graphql({
          query: listParkReviews,
          variables: {
              filter: {
                  parkId: {
                      eq: parkId
                  }
              }
          }
      }) as Promise<GraphQLResult<ListParkReviewsQuery>>

      reviewsPromise.then((res) => {
          console.log(res.data?.listParkReviews?.items)
          const items = (res.data?.listParkReviews?.items ?? []).filter((item): item is ParkReview => Boolean(item))
          let sumRating: number | null = null
          items.forEach(item => sumRating = (sumRating || 0) + item.rating)
          setAverageRating(sumRating && items.length > 0 ? (sumRating/items.length) : null)
          console.log(sumRating)
      })
  }, [parkId])


  if (!option) {
    return <></>
  }


  return <div className={'SearchResult'}>
    <div>
      <Typography variant="h4" paddingTop={'1rem'} fontWeight={'bolder'} component="div">{option.name}</Typography>
      {averageRating && <Rating name="average-rating" key={option.id} value={averageRating} readOnly precision={0.25} />}
      <Typography variant={"body1"} paddingTop={'1rem'} paddingBottom={'1rem'}
        component={'div'}>{option.description}</Typography>
      <div className={'SearchResult-Cards'}>
        <Paper elevation={2}>
          <CardContent>{
            Array.isArray(option.restrictionDescription) ?
              <>
                <Typography variant="h6" fontWeight={'bolder'} component="div">Regulations:</Typography>
                <div
                  className={"SearchResult-multiRestrictionDescriptions"}>{option.restrictionDescription.map((desc, index) =>
                    <div key={index}>
                      <Typography variant="subtitle1" fontWeight={'bolder'} component="div">{desc.name}</Typography>
                      <Typography variant="body2">{desc.value}</Typography>
                    </div>)}
                </div>
              </> :
              <>
                <Typography variant="h6" fontWeight={'bolder'} component="div">Regulation:</Typography>
                <Typography variant="body2">{option.restrictionDescription || "Unknown"}</Typography>
              </>
          }</CardContent>
        </Paper>
        <Paper elevation={2}>
        <CardContent>
            {option.information &&
              <>
                <Typography variant="h6" fontWeight={'bolder'} component="div">Properties:</Typography>
                <div className={'SearchResult-information'}>{option.information.map((info, index) =>
                  <div key={index}>
                    <Typography variant="subtitle1" fontWeight={'bolder'} component="div">{info.name}</Typography>
                    <Typography variant="body2">{info.value}</Typography>
                  </div>)}
                </div>
              </>
            }
          </CardContent>
        </Paper>
      </div>
    </div>
    <div className={'SearchResult-buttonGroup'}>
      <Button
        variant="contained"
        size={'large'}
        endIcon={<SendIcon />}
        onClick={async () => {
          await navigator.share({ url: document.location.href, title: "Dog Zones", text: option.name })
        }}
      >
        Share
      </Button>
      <Button
        variant="contained"
        size={'large'}
        endIcon={<AssistantDirectionIcon />}
        onClick={() => {
          if (location) {
            window.open(`https://maps.google.com?q=${location.lat},${location.lon}`)
          }
          window.open(`https://maps.google.com?q=${option.searchArea.lat},${option.searchArea.lon}`)
        }}
      >
        Navigate
      </Button>
    </div>
    <ReviewSection parkId={option.id} name={option.name} />
  </div>
}
