export const auth_config = {
	aws_project_region: process.env.REACT_APP__BACKEND_STACK__AppRegion,
	aws_user_pools_id: process.env.REACT_APP__BACKEND_STACK__UserPoolId,
	aws_user_pools_web_client_id: process.env.REACT_APP__BACKEND_STACK__UserPoolClientId,
	aws_cognito_identity_pool_id: process.env.REACT_APP__BACKEND_STACK__IdentityPoolId,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP__BACKEND_STACK__AppSyncAPIEndpoint,
	aws_appsync_region: process.env.REACT_APP__BACKEND_STACK__AppRegion,
	aws_appsync_authenticationType: process.env.REACT_APP__BACKEND_STACK__AppSyncAuthType,
}


// {
// 	aws_project_region: process.env.REACT_APP__BACKEND_STACK__AppRegion,
// 	Auth: {
// 		region: process.env.REACT_APP__BACKEND_STACK__AppRegion,
// 		userPoolId: process.env.REACT_APP__BACKEND_STACK__UserPoolId,
// 		userPoolWebClientId: process.env.REACT_APP__BACKEND_STACK__UserPoolClientId,
// 		identityPoolId: process.env.REACT_APP__BACKEND_STACK__IdentityPoolId,
// 	},
// 	aws_appsync_graphqlEndpoint: process.env.REACT_APP__BACKEND_STACK__AppSyncAPIEndpoint,
// 	aws_appsync_region: process.env.REACT_APP__BACKEND_STACK__AppRegion,
// 	aws_appsync_authenticationType: process.env.REACT_APP__BACKEND_STACK__AppSyncAuthType,
// }

// {
//   "aws_project_region": process.env.REACT_APP_AWS_DEFAULT_REGION,
//   // "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
//   "aws_cognito_region": process.env.REACT_APP_AWS_DEFAULT_REGION,
//   "aws_user_pools_id": process.env.REACT_APP__BACKEND_STACK__UserPoolId,
//   "aws_user_pools_web_client_id": process.env.REACT_APP__BACKEND_STACK__UserPoolClientId
// };