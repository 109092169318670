/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createParkReview = /* GraphQL */ `mutation CreateParkReview(
  $input: CreateParkReviewInput!
  $condition: ModelParkReviewConditionInput
) {
  createParkReview(input: $input, condition: $condition) {
    id
    parkId
    title
    description
    rating
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParkReviewMutationVariables,
  APITypes.CreateParkReviewMutation
>;
export const updateParkReview = /* GraphQL */ `mutation UpdateParkReview(
  $input: UpdateParkReviewInput!
  $condition: ModelParkReviewConditionInput
) {
  updateParkReview(input: $input, condition: $condition) {
    id
    parkId
    title
    description
    rating
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateParkReviewMutationVariables,
  APITypes.UpdateParkReviewMutation
>;
export const deleteParkReview = /* GraphQL */ `mutation DeleteParkReview(
  $input: DeleteParkReviewInput!
  $condition: ModelParkReviewConditionInput
) {
  deleteParkReview(input: $input, condition: $condition) {
    id
    parkId
    title
    description
    rating
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteParkReviewMutationVariables,
  APITypes.DeleteParkReviewMutation
>;
