import React, { useEffect } from "react";
import { AppNavigator } from "../../components/AppNavigator";
import { userPool } from "../../userpool";
import "./AccountPage.css"
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { logout } from "../../services/auth/logout";

export function AccountPage() {
  const user = userPool.getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      navigate("/")
    }
  }, [user, navigate])

  return <div className={'AccountPage'}>
    <div className={"AccountPageContent"}>
      <Typography component="h1" variant="h5">
        Welcome {user?.getUsername()}
      </Typography>
      <Typography component="div" variant="body1">
        The Account page is currently under construction
      </Typography>
      <Button
        onClick={logout}
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Log out
      </Button>
    </div>
    <AppNavigator />
  </div>
}