import { useAuthenticator } from "@aws-amplify/ui-react"
import { generateClient } from 'aws-amplify/api';
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { ListParkReviewsQuery, ParkReview } from 'API'
import { listParkReviews } from "graphql/queries"
import { useEffect, useState } from "react"
import { Avatar, Button, Card, CardContent, Divider, Paper, Rating, Typography } from "@mui/material"
import { CreateReview } from "./CreateReview";
import './ReviewSection.css'
import { deleteParkReview } from "graphql/mutations";


type ReviewSectionProps = {
    parkId: string,
    name: string
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: '2rem',
            height: '2rem'
        },
        children: `${name[0]}`,
    };
}


export const ReviewSection = ({ parkId, name }: ReviewSectionProps) => {
    const { user } = useAuthenticator((context) => [context.user])
    const client = generateClient()
    const [reviews, setReviews] = useState<[] | ParkReview[]>([])
    const [usersReview, setUsersReview] = useState<ParkReview>()
    const [createReview, setCreateReview] = useState<boolean>(false)

    // const createRes =  as GraphQLResult<CreateParkReviewMutation>

    useEffect(() => {
        const reviewsPromise = client.graphql({
            query: listParkReviews,
            authMode: user ? 'userPool' : 'iam',
            variables: {
                filter: {
                    parkId: {
                        eq: parkId
                    }
                }
            }
        }) as Promise<GraphQLResult<ListParkReviewsQuery>>

        reviewsPromise.then((res) => {
            console.log(res.data?.listParkReviews?.items)
            const items = res.data?.listParkReviews?.items ?? []

            setReviews(items.filter((item): item is ParkReview => Boolean(item)))
            setUsersReview(items.find(item => item?.owner === user.username) || undefined)
        })
    }, [user, createReview, parkId])

    return (
        <>
            <Typography variant="h6" fontWeight={'bolder'} component="div" paddingTop={'3rem'}>Reviews</Typography>
            {reviews.map(review => {
                return <Paper
                    key={review.id}
                    elevation={2}
                >
                    <CardContent>
                        <div className="ReviewSection-header">
                            <div className="ReviewSection-user">
                                <Avatar  {...stringAvatar(review.owner || "ANNON")} />
                                <Typography variant="subtitle1" fontWeight={'bolder'} component="div">{review.owner || "ANNON"}</Typography>
                            </div>
                            {usersReview?.id === review.id && (
                                <Button variant="contained" color="error" onClick={async () => {
                                    await client.graphql({
                                        query: deleteParkReview,
                                        variables: {
                                            input: {
                                                id: usersReview.id
                                            },
                                        },
                                    })
                                    window.location.reload(); // ew i need to fix this
                                }}>
                                    Delete
                                </Button>
                            )}
                        </div>
                        <div className="ReviewSection-review">
                            <Rating name="rating" value={review.rating} readOnly />
                            <Typography variant="subtitle1" fontWeight={'bolder'} component="div" textTransform={'capitalize'} className="ReviewSection-review-capitalize-first-letter">{review.title}</Typography>
                            <Typography variant="body1" component="div" className="ReviewSection-review-capitalize-first-letter" >{review.description}</Typography>
                        </div>
                    </CardContent>
                </Paper>
            }
            )}

            <Button
                variant="contained"
                size={'large'} onClick={() => setCreateReview(true)}>{usersReview ? "Edit your review" : "Write a review"}
            </Button>
            <Divider />
            {createReview && <CreateReview parkId={parkId} name={name} review={usersReview} onClose={() => { setCreateReview(false) }} />}
        </>
    )
}

